import {CdnImage} from 'components/core/CdnImage'
import Link from 'next/link'
import React, { Fragment } from 'react'

type Props = {
  noTopPadding?: boolean
}

const TopMenuBar = (props: Props) => {
  return (
    <Fragment>
      <div id="header-top" className={`lg:block bg-black w-full z-50 hidden ${props.noTopPadding ? '' : ''}`}>
        <div className="container flex items-center justify-between">
          <div className="flex items-center">
            <a href="https://www.witopia.com" className="w-[60px] mr-[65px] inline-block">
              <CdnImage className="w-[60px] h-[36px]" fileName="Witopia_Logo.svg" alt="Witopia Logo" />
            </a>
            <ul className='top-menu'>
              <li className="group relative text-white py-[10px] block font-Verdana hover:text-[#b3b3b3]">
                <span className="relative arrow-down">Products</span>
                <ul className="group-hover:block dropdown-menu absolute -right-3 hidden h-auto w-44 bg-white mt-[10px] divide-y divide-[#e0e0e0] shadow-lg">
                  <li className="text-center">
                    <a className="p-3 block" href="https://www.personalvpn.com/">
                      <CdnImage className="w-[110px] h-auto inline-block" fileName="personalVPN_text_logo.png" alt="personalVPN" />
                    </a>
                  </li>
                  <li className="text-center">
                    <a className="px-3 py-2 block" href="https://securemyemail.com">
                      <CdnImage className="w-[55px] h-auto inline-block" fileName="SecureMyEmail_text_logo.png" alt="SecureMyEmail" />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <ul className="flex flex-wrap items-center justify-center">
              <li className="group relative py-[10px] block mr-4">
                <a className="text-white font-Verdana hover:text-[#b3b3b3]" href="https://www.witopia.com/blog">
                  <span className="">Blog</span>
                </a>
              </li>
              <li className="group relative text-white py-[10px] block font-Verdana hover:text-[#b3b3b3] mr-7">
                <span className="relative arrow-down">Support</span>
                <ul className="group-hover:block dropdown-menu absolute -right-3 hidden h-auto w-44 bg-black mt-[10px] shadow-lg">
                  <li className="text-right">
                    <a className="p-3 block text-white hover:text-[#b3b3b3]" href="https://www.personalvpn.com/support/">
                      personalVPN
                    </a>
                  </li>
                  <li className="text-right">
                    <a className="p-3 block text-white hover:text-[#b3b3b3]" href="/support">
                      SecureMyEmail
                    </a>
                  </li>
                </ul>
              </li>
              <li className="group relative py-[10px] block mr-4">
                <a className="text-white font-Verdana hover:text-[#b3b3b3]" href="https://www.personalvpn.com/contact/">
                  <span className="">Contact Us</span>
                </a>
              </li>
              <li className="group relative text-white py-[10px] block font-Verdana hover:text-[#b3b3b3]">
                <span className="relative arrow-down">Dashboards</span>
                <ul className="group-hover:block dropdown-menu absolute -right-3 hidden h-auto w-44 bg-black mt-[10px] shadow-lg">
                  <li className="text-right">
                    <a className="p-3 block text-white hover:text-[#b3b3b3]" href="https://www.securemyemail.com/account/dashboard">
                      SecureMyEmail Dashboard
                    </a>
                  </li>
                  <li className="text-right">
                    <a className="p-3 block text-white hover:text-[#b3b3b3]" href="https://my.personalvpn.com/">
                      VPN Dashboard
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default TopMenuBar
