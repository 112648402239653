import Button from 'components/core/Button'
import { LocalImage } from 'components/core/CdnImage'
import { MenuModel } from 'models/menu/MenuModel'
import Link from 'next/link'
import React, { Fragment, FunctionComponent } from 'react'
import { generateMenuIdFromSlug } from 'utils/GenerateMenuIdFromSlug'

type Props = {
  data: MenuModel
  isDark?: boolean
}

const Footer: FunctionComponent<Props> = (props) => {
  const propsData = props.data
  const attributes = propsData.data.attributes
  return (
    <Fragment>
      <footer className={`text-gray-600 ${attributes.styleClassNames} ${props.isDark && 'bg-[#222222]'}`}>
        <div className="container !max-w-[1300px] pt-24 flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
          <div className="grid md:grid-cols-3 lg:grid-cols-5 gap-5 md:gap-12 lg:gap-24 md:text-left text-center">
            {props.isDark ? (
              <Fragment>
                <div className="px-4">
                  <div className="mb-8">
                    <a href="" target="_blank" rel="noreferrer">
                      <LocalImage width={110} height={40} fileName="footer-logo.svg" alt="securemyemail" />
                    </a>
                  </div>
                  <ul className="md:justify-start flex mb-[42px] justify-center gap-x-2">
                    <li>
                      <a href="https://www.facebook.com/SecureMyEmail" target="_blank" rel="noreferrer">
                        <LocalImage width={28} height={28} fileName="ic-fb.svg" alt="Facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/SecureMyEmail" target="_blank" rel="noreferrer">
                        <LocalImage width={28} height={28} fileName="ic-tw.svg" alt="Twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/SecureMyEmail" target="_blank" rel="noreferrer">
                        <LocalImage width={28} height={28} fileName="ic-yt.svg" alt="Youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/SecureMyEmail" target="_blank" rel="noreferrer">
                        <LocalImage width={28} height={28} fileName="ic-ins.svg" alt="Instagram" />
                      </a>
                    </li>
                  </ul>
                  <div className="subscribe-wrap">
                    <h4 className="font-Manrope text-[14px] font-normal leading-[38px] text-[#001064]">Subscribe</h4>
                    <div className="subscribe-form relative mb-2 z-10">
                      <div className="inputWrap">
                        <div className="inputbox relative">
                          <input type="email" name="email" className="" placeholder="Enter your email" />
                          <button className="absolute right-[8px] top-0 subscribe-btn">Subscribe</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div>
                  <div className="mb-8 flex justify-center md:justify-start">
                    <a href="" target="_blank" rel="noreferrer">
                      <LocalImage width={110} height={40} fileName="footer-logo.svg" alt="securemyemail" />
                    </a>
                  </div>
                  <ul className="md:justify-start flex mb-[42px] justify-center gap-x-2">
                    <li>
                      <a href="https://www.facebook.com/SecureMyEmail" target="_blank" rel="noreferrer">
                        <LocalImage width={28} height={28} fileName="ic-fb.svg" alt="Facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/SecureMyEmail" target="_blank" rel="noreferrer">
                        <LocalImage width={28} height={28} fileName="ic-tw.svg" alt="Twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/SecureMyEmail" target="_blank" rel="noreferrer">
                        <LocalImage width={28} height={28} fileName="ic-yt.svg" alt="Youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/SecureMyEmail" target="_blank" rel="noreferrer">
                        <LocalImage width={28} height={28} fileName="ic-ins.svg" alt="Instagram" />
                      </a>
                    </li>
                  </ul>
                  <div className="subscribe-wrap">
                    <h4 className="font-Manrope text-[14px] font-normal leading-[38px] text-[#001064]">Subscribe</h4>
                    <div className="subscribe-form relative mb-2 z-10">
                      <div className="inputWrap">
                        <div className="inputbox relative">
                          <input type="email" name="email" className="" placeholder="Enter your email" />
                          <button className="absolute right-[8px] top-2 sm:top-0 subscribe-btn">Subscribe</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
            {attributes.items.map((item, index) => (
              <div key={index}>
                <h4 className="text-[#001064] font-Manrope text-sm uppercase mb-5 font-bold p-0">{item.title}</h4>
                <ul className="list-none">
                  {item.links.map((link, index) => (
                    <Link key={index} href={link.href ?? '#'} passHref>
                      <li className="cursor-pointer font-Manrope" id={generateMenuIdFromSlug(link)}>
                        <a href={link.href} className={`text-sm leading-[38px] ${props.isDark ? 'text-white/80' : 'text-[#001064]'}`}>
                          {link.title}
                        </a>
                      </li>
                    </Link>
                  ))}
                </ul>
                <ul className="md:justify-start flex mb-[10px] justify-center gap-x-2">
                  {item.buttons.map((button, index) => (
                    <li key={index}>
                      <Button data={button} extraClassNames="w-5" />
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="md:text-left block text-center copyright mt-10">
          <div className="container !max-w-[1300px]">
            <div className="font-Manrope text-[13px] pt-5 pb-8 text-[#001064]">
              Copyright © {new Date().getFullYear()} {attributes.copyrightText}
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  )
}

export default Footer
